import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['ru', 'en'],
        fallbackLng: 'en',
        keySeparator: '.',
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subDomain'],
            caches: ['cookie'],
        },
        interpolation: {
            escapeValue: false,
        },

        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
    })

export default i18next
